import { mapThaiMonth } from '@/constants/date'
import dayjs from 'dayjs'
import { ref } from 'vue'
import { useShopStore } from '@/pinia/shop/shop.pinia'
import { IPaymentDetails, IUpdateCostPayload, Order } from '@/api/report/type'
import { updateCost } from '@/api/report/report.api'
import { useUserStore } from '@/pinia/user/user.pinia'
import { useMiddleAccount } from '@/use/middleAccount'

export interface ICost {
	_id: string
	costProduct: number
	costShipment: number
	costAds: number
	costPackage: number
	costOther: number
	totalCost: number
	sales: number
	profit: number
	dashboardAnalyticsDate: string
	orders: Order[]
	totalWaitPaymentDetail: number
	costMarketplaceDetail: IPaymentDetails
}

export const useCost = () => {
	const { isDealerType } = useMiddleAccount()
	const userStore = useUserStore()
	const shopStore = useShopStore()
	const costRowData = ref<ICost>({
		_id: '',
		costProduct: 0,
		costShipment: 0,
		costAds: 0,
		costPackage: 0,
		costOther: 0,
		totalCost: 0,
		sales: 0,
		profit: 0,
		dashboardAnalyticsDate: '',
		orders: [],
		totalWaitPaymentDetail: 0,
		costMarketplaceDetail: {
			totalRevenue: 0, // ยอดขายสินค้า
			totalFee: 0, // ค่าธรรมเนียมรวม
			totalSettlement: 0, // ยอดที่จะได้รับ
			CODFee: 0, // cod charge
			commissionFee: 0, // commission
			shippingFee: 0, // shipment
			transactionFee: 0, // credit payment etc.
			serviceFee: 0, // marketplace fee
			promotionFee: 0, // promotion ค่าธณรมเนียมการส่งเสริมการขาย
			refund: 0, // เงินคืนผลเป็นบวก ไม่ติดลบ
			otherFee: 0
		}
	})

	const costOrder = ref()
	const totalCost = ref(0)
	const totalSales = ref(0)
	const totalProfit = ref(0)

	const fetchCostlist = (
		cost: ICost[],
		startDate: string,
		endDate: string
	) => {
		const daysDiff = dayjs(endDate).diff(dayjs(startDate), 'day')

		// ออเดอร์
		const orderLabels = []
		const sumData = []
		const costData = []
		const profitData = []

		if (daysDiff > 0) {
			for (let i = daysDiff; i >= 0; i--) {
				const dateLabel = dayjs(endDate).subtract(i, 'day')
				const calendarLabel = `${dateLabel.format('DD')} ${
					mapThaiMonth[dateLabel.format('MM')].short
				} ${dateLabel.format('YY')}`

				orderLabels.push(calendarLabel)

				const findMatchDate: any = cost.find(
					(each: { sales: number; dashboardAnalyticsDate: string }) => dayjs(each.dashboardAnalyticsDate).format('YYYY-MM-DD') === dateLabel.format('YYYY-MM-DD')
				)

				if (findMatchDate) {
					const _totalCost =
						(findMatchDate.costProduct || 0) +
						(findMatchDate.costShipment || 0) +
						(findMatchDate.costAds || 0) +
						(findMatchDate.costPackage || 0) +
						(findMatchDate.costOther || 0) +
						(findMatchDate.costMarketplaceDetail?.totalFee || 0)

					const _profit = findMatchDate.sales - _totalCost

					sumData.push(findMatchDate.sales)
					costData.push(_totalCost)
					profitData.push(_profit)

					totalCost.value += _totalCost
					totalSales.value += findMatchDate.sales
					totalProfit.value += _profit
				} else {
					sumData.push(0)
					costData.push(0)
					profitData.push(0)
				}
			}
		} else {
			for (let i = 0; i < 24; i++) {
				let _sale = 0
				let _cost = 0
				if (cost?.length > 0 && cost?.[0]?.orders) {
					for (const order of cost[0].orders) {
						const orderHour = dayjs(order.createdAt).hour()
						if (orderHour === i) {
							_sale += order.saleInOrder
							_cost += order.costProductInOrder
						}
					}
				}

				if (_sale > 0) {
					_cost += cost[0].costShipment || 0
				}

				if (i === 23) {
					const costAtIndex0 = cost?.[0] || {}
					const _totalCost =
						(costAtIndex0?.costShipment || 0) +
						(costAtIndex0?.costAds || 0) +
						(costAtIndex0?.costPackage || 0) +
						(costAtIndex0?.costOther || 0) + 
						(costAtIndex0.costMarketplaceDetail?.totalFee || 0)

					sumData.push(_sale)
					costData.push(_totalCost)
					profitData.push(_sale - _totalCost)
				} else {
					sumData.push(_sale)
					costData.push(_cost)
					profitData.push(_sale - _cost)
				}
				orderLabels.push(`${i}:00`)
				
			}
		}

		const _data: any = {
			labels: orderLabels,
			datasets: []
		}

		if(isDealerType.value){
			_data.datasets.push({
				label: 'ยอดขาย',
				data: sumData,
				fill: false,
				borderColor: '#1B3CEB',
				backgroundColor: '#1B3CEB',
				borderWidth: 2,
				pointRadius: 1,
				pointHoverRadius: 8,
				tension: 0.4,
			})
		}else{
			_data.datasets.push({
				label: 'ต้นทุน',
				data: costData,
				fill: false,
				borderColor: '#e6a23c',
				backgroundColor: '#e6a23c',
				borderWidth: 2,
				pointRadius: 1,
				pointHoverRadius: 8,
				tension: 0.4,
			},
			{
				label: 'ยอดขาย',
				data: sumData,
				fill: false,
				borderColor: '#1B3CEB',
				backgroundColor: '#1B3CEB',
				borderWidth: 2,
				pointRadius: 1,
				pointHoverRadius: 8,
				tension: 0.4,
			},
			{
				label: 'กำไร',
				data: profitData,
				fill: false,
				borderColor: '#67c23a',
				backgroundColor: '#67c23a',
				borderWidth: 2,
				pointRadius: 1,
				pointHoverRadius: 8,
				tension: 0.4,
			},)
		}
		
		_data.datasets = _data.datasets.filter((each:any) => { 
			// ดูได้เฉพาะ shop owner
			if (userStore.isOwner) {
				return each
			}
			return ['ยอดขาย'].includes(each.label)
		})

		const lineChart = {
			id: 'costlineChart',
			type: 'line',
			data: _data,
			options: {
				animation: {
					duration: 200,
				},
				plugins: {
					legend: {
						display: true,
						position: 'bottom',
						align: 'end',
						labels: {
							usePointStyle: true,
							pointStyle: 'circle',
							boxWidth: 5,
							boxHeight: 5,
							fullSize: true,
						},
					},
				},
				scales: {
					x: {},
					y: {
						beginAtZero: true,
					},
				},
			},
		}

		costOrder.value = lineChart
	}

	const onUpdateCost = async () => {
		const payload: IUpdateCostPayload = {
			shopId: shopStore.selectedShopId as string,
			_id: costRowData.value._id || '',
			sales: Number(costRowData.value.sales),
			costProduct: Number(costRowData.value.costProduct),
			costShipment: Number(costRowData.value.costShipment),
			costAds: Number(costRowData.value.costAds),
			costPackage: Number(costRowData.value.costPackage),
			costOther: Number(costRowData.value.costOther),
			costMarketplaceDetail: costRowData.value.costMarketplaceDetail,
			totalWaitPaymentDetail: costRowData.value.totalWaitPaymentDetail,
			dashboardAnalyticsDate: dayjs(costRowData.value.dashboardAnalyticsDate).format('YYYY-MM-DD'),
		}
		await updateCost(payload)
	}

	const updateCostRowData = (rowData: any) => {
		costRowData.value = rowData
	}

	return {
		updateCostRowData,
		fetchCostlist,
		onUpdateCost,
		costOrder,
		totalCost,
		totalSales,
		totalProfit,
		costRowData,
	}
}
